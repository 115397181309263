import "core-js/modules/es.array.push.js";
import bus from '@/common/function/bus.js';
export default {
  data() {
    return {
      mobile: '',
      code: '',
      areaCodeList: [],
      mobile_code: '',
      time: 60 * 10,
      isGetCode: false,
      //是否获取验证码
      timer: '',
      //定时器

      curSelect: 1,
      user_email: '',
      userInfor: ''
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    curSelect() {
      this.code = '';
      clearInterval(this.timer);
      this.isGetCode = false;
    }
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    async init() {
      await this.getUserInfo();
      await this.getAreaCode();
    },
    // 获取会员信息
    getUserInfo() {
      return new Promise((resolve, reject) => {
        this.$http.userInfo().then(res => {
          if (res.code == 1) {
            this.userInfor = res.data;
            this.user_email = this.userInfor.user_email;
            this.mobile = this.userInfor.mobile;
            this.mobile_code = this.userInfor.mobile_code;
          } else if (res.code == -201) {} else {
            this.$message.error(res.msg);
          }
          resolve();
        });
      });
    },
    // 获取区号
    getAreaCode() {
      return new Promise((resolve, reject) => {
        this.$http.getAreaCode().then(res => {
          if (res.code == 1) {
            this.areaCodeList = res.data;
            if (!this.mobile) {
              this.mobile_code = res.data[0];
            }
          } else {
            this.$message.error(res.data.msg);
          }
          resolve();
        }).catch(v => {
          console.log(v);
        });
      });
    },
    // 区号点击
    areaCodeClick(command) {
      this.mobile_code = command;
    },
    // 获取验证码
    getCode() {
      if (!this.mobile && this.curSelect == 1) {
        this.$message.error(this.$t('tips.mobilePhoneNumberCannotBeEmpty'));
        return;
      }
      if (!this.user_email && this.curSelect == 2) {
        this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'));
        return;
      }
      let data = {
        type: 7,
        is_test: 0
      };
      if (this.curSelect == 1) {
        this.$set(data, "mobile", this.mobile);
        this.$set(data, "mobile_code", this.mobile_code);
      } else {
        this.$set(data, "user_email", this.user_email);
      }
      this.$http.getCode(data).then(res => {
        if (res.code == 1) {
          this.$message.success(this.$t('sendSuccess'));
          this.isGetCode = true;
          this.timer = setInterval(() => {
            if (this.time == 1) {
              clearInterval(this.timer);
              this.time = 60 * 1;
              this.isGetCode = false;
            }
            this.time--;
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      }).catch(v => {});
    },
    confirmClick() {
      if (!this.mobile && this.curSelect == 1) {
        this.$message.error(this.$t('tips.mobilePhoneNumberCannotBeEmpty'));
        return;
      }
      if (!this.user_email && this.curSelect == 2) {
        this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'));
        return;
      }
      if (!this.code) {
        this.$message.error(this.$t('tips.codeCannotBeEmpty'));
        return;
      }
      let data = {
        code: this.code,
        type: 7
      };
      if (this.curSelect == 1) {
        this.$set(data, "mobile", this.mobile);
        this.$set(data, "mobile_code", this.mobile_code);
      } else {
        this.$set(data, "user_email", this.user_email);
      }
      this.$http.isVerify(data).then(res => {
        if (res.code == 1) {
          this.$router.push({
            path: '/personInfor',
            query: {
              curCenterIndex: 0
            }
          });
          bus.$emit('personInforEmit');
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  }
};