var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "person_infor"
  }, [_c('div', {
    staticClass: "login_top"
  }, [_c('div', {
    staticClass: "login_title"
  }, [_vm._v(_vm._s(_vm.$t('verify')))])])]), _c('div', {
    staticClass: "select_radio"
  }, [_c('el-radio-group', {
    attrs: {
      "text-color": "#F39800",
      "fill": "#F39800"
    },
    model: {
      value: _vm.curSelect,
      callback: function ($$v) {
        _vm.curSelect = $$v;
      },
      expression: "curSelect"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": 1
    }
  }, [_vm._v(_vm._s(_vm.$t('mobileVerify')))]), _c('el-radio', {
    attrs: {
      "label": 2
    }
  }, [_vm._v(_vm._s(_vm.$t('emailVerify')))])], 1)], 1), _vm.curSelect == 1 ? _c('div', {
    staticClass: "input_item"
  }, [_vm._m(0), _c('div', {
    staticClass: "dropdown"
  }, [_c('el-dropdown', {
    on: {
      "command": _vm.areaCodeClick
    }
  }, [_c('span', {
    staticClass: "el-dropdown-link"
  }, [_vm._v(" " + _vm._s(_vm.mobile_code)), _c('i', {
    staticClass: "el-icon-caret-bottom el-icon--right"
  })]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.areaCodeList, function (item, index) {
    return _c('el-dropdown-item', {
      key: index,
      attrs: {
        "command": item
      }
    }, [_vm._v(_vm._s(item) + " ")]);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mobile,
      expression: "mobile"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('login.inputPhone')
    },
    domProps: {
      "value": _vm.mobile
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.mobile = $event.target.value;
      }
    }
  })])]) : _vm._e(), _vm.curSelect == 2 ? _c('div', {
    staticClass: "input_item"
  }, [_vm._m(1), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user_email,
      expression: "user_email"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('login.inputEmail')
    },
    domProps: {
      "value": _vm.user_email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.user_email = $event.target.value;
      }
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "input_item"
  }, [_vm._m(2), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.code,
      expression: "code"
    }],
    attrs: {
      "type": "text",
      "maxlength": "6",
      "placeholder": _vm.$t('login.inputCode')
    },
    domProps: {
      "value": _vm.code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.code = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "code"
  }, [!_vm.isGetCode ? _c('div', {
    staticClass: "code1",
    on: {
      "click": _vm.getCode
    }
  }, [_vm._v(_vm._s(_vm.$t('login.getCode')))]) : _c('div', {
    staticClass: "code2"
  }, [_vm._v(_vm._s(_vm.$t('login.emailSendAgain')))])])]), _c('div', {
    staticClass: "important_tips"
  }, [_vm._v(" " + _vm._s(_vm.$t('importantTips')) + " ")]), _c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.confirmClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('nextStep')) + " ")])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input_item_icon"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/login/phone.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input_item_icon"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/login/email.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input_item_icon"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/login/code.png")
    }
  })]);
}];
export { render, staticRenderFns };